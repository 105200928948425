import { useUserAccept } from 'api/users';
import { linkHelper } from 'linkHelper';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { useProfile } from 'api/profile';

export default function UserAccept({ isWpSiteControl }: { readonly isWpSiteControl: boolean }) {
  const qsParams = qs.parse(window.location.search);
  const { mutateAsync, isSuccess } = useUserAccept();
  const navigate = useNavigate();
  const { isLoading, data: profileData } = useProfile();
  const token = typeof qsParams.token === 'string' ? qsParams.token : null;

  useEffect(() => {
    const profile = profileData?.data.result;
    const passwordSet = profile?.client?.password_set;

    if (!isLoading && !isWpSiteControl && token) {
      mutateAsync(token);
      if (typeof passwordSet === 'boolean' && passwordSet === false) {
        navigate(
          linkHelper.setPassword.getLink({
            token,
            userId: profile?.client?.id ?? '',
            email: profile?.client?.email ?? '',
          })
        );
      } else {
        navigate(linkHelper.dashboard.getLink());
      }
    }
  }, [isLoading, isWpSiteControl, token, profileData]);
  useEffect(() => {
    if (isSuccess) {
      navigate(linkHelper.dashboard.getLink());
    }
  }, [isSuccess]);

  return null;
}
